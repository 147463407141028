<template>
  <div>
    <h4>레시피 리스트</h4>

    <el-button type="primary" @click="openDialog">레시피 추가</el-button>

    <el-table :data="recipes">
      <el-table-column prop="title" label="제목" />
      <el-table-column label="작성자">
        <template #default="scope">
          {{ scope.row.user.firstName }} {{ scope.row.user.lastName }}
        </template>
      </el-table-column>
      <el-table-column label="생성일시">
        <template #default="scope">
          {{ new Date(scope.row.createdAt).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button
            size="mini"
            @click="
              $router.push({ name: 'RecipeEdit', params: { id: scope.row.id } })
            "
          >
            수정
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="archiveRecipe(scope.row)"
          >
            삭제
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="dialogVisible" title="레시피 추가" width="75%">
      <div>
        <el-input v-model="title" placeholder="레시피 제목" />
        <el-button size="mini" @click="createRecipe">추가</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import RecipeService from "../services/RecipeService";

export default {
  data() {
    return {
      recipes: [],
      dialogVisible: false,
      title: "",
    };
  },
  methods: {
    async retrieveRecipes() {
      try {
        const resp = await RecipeService.getAll();
        this.recipes = resp.data;
        console.log("recipes: ", resp.data);
      } catch (e) {
        console.log(e);
      }
    },
    openDialog() {
      this.title = "";
      this.dialogVisible = true;
    },
    async createRecipe() {
      if (!this.title) {
        this.$message.error(`레시피의 제목을 입력해주세요.`);
        return;
      }
      try {
        const resp = await RecipeService.create({
          title: this.title,
        });
        console.log("recipe: ", resp.data);
        this.$message.success(
          "레시피가 추가되었습니다. 레시피 수정 페이지로 이동합니다."
        );
        this.$router.push({ name: "RecipeEdit", params: { id: resp.data.id } });
      } catch (e) {
        console.log(e);
      }
    },
    async archiveRecipe(recipe) {
      try {
        await this.$confirm(
          `정말로 레시피 (${recipe.title})를 삭제하시겠습니까?`
        );
        await RecipeService.update(recipe.id, {
          archived: true,
        });

        this.$message.success("레시피가 삭제되었습니다.");
        this.retrieveRecipes();
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.retrieveRecipes();
  },
};
</script>

<style>
</style>